<template>
  <div class="homepage">
    <public-header></public-header>
    <!-- banner -->
    <div class="banner">
       <img src="@/assets/images/about-mobile/detail/nick.png" :alt='$t("bannerTitle")'>
    </div>
    <!-- 人物介绍 -->
    <div class="character">
        <h5>{{$t("teamItem3")}}{{$t("teamItem31")}}</h5>
        <p>{{$t("teamItemPost3")}}</p>
        <p>{{$t("teamItemGet3")}}</p>
        <div class="link">
          <a href="https://www.linkedin.com/in/nicholas-manthey/"><img src="../../..//assets/images/about-mobile/detail/link.png" alt=""></a>
        </div>
        <p>{{$t("teamItemDetail3")}}</p>
        <div class="button">
          <router-link :to="{path:'aboutUs'}">
            <img src="@/assets/images/about-mobile/detail/back.png" alt='btn'>
            <p>{{$t("back1")}}</p>
          </router-link>
        </div>
    </div>
    <public-footer></public-footer>
  </div>
</template>
<script>
export default {
  name: 'carl-mobile',
   methods: {
   
  }
}
</script>
<style scoped>
 .banner {
   width: 100%;
   height: 4.2rem;
   position: relative;
   padding: 0 0.3rem;
 }
 .banner img ,.character .button img ,.character .link img{
   width: 100%;
   height: 100%;
   object-fit: contain;
 }
.character {
  width: 100%;
  height: 8.3rem;
  padding: 0.3rem;
}
.character h5 {
  font-size: 0.3rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.42rem;
  margin-bottom: 0.2rem;
}
.character .link {
  width: 0.42rem;
  height: 0.42rem;
  margin-bottom: 0.2rem;
}
.character p {
  font-size: 0.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.28rem;
  margin-bottom: 0.2rem;
}
.character .button {
  margin-top: 0.52rem;
  width: 1.32rem;
  height: 0.52rem;
  position: relative;
}
.character .button p {
  color: #fff;
  font-size: 0.16rem;
  font-weight: 600;
  line-height: 0.52rem;
  letter-spacing: 0.01rem;
  margin-bottom: 0.2rem;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
  left: 0;
}

</style>
