<template>
  <div class="homepage">
    <public-header></public-header>
    <!-- banner -->
    <div class="banner">
       <img src="@/assets/images/project-mobile/detail/bannerjiangxi.png" :alt='$t("bannerTitle")'>
    </div>
   <div class="project-detail">
     <div class="project-detail-inner">
       <h5>
         {{$t("projectItemTitle1Mobile")}}
       </h5>
       <!-- <div class="map">
         <img src="@/assets/images/project-mobile/detail/map.png" alt="">
         <div class="position">
           <img src="@/assets/images/project-mobile/detail/postion.png" alt="">
         </div>
         <div class="title">
           <span>{{$t("projectItemTitle1Mobile")}}</span>
         </div>
       </div> -->
       <h6>{{$t("projectJiangXiTitle1")}}</h6>
        <p>{{$t("projectJiangXiTitleDetail1")}}</p>
        <h6>{{$t("projectJiangXiTitle2")}}</h6>
        <p>{{$t("projectJiangXiTitleDetail2")}}</p>
        <h6>{{$t("projectJiangXiTitle3")}}</h6>
        <p>{{$t("projectJiangXiTitleDetail3")}}</p>
        <h6>{{$t("projectJiangXiTitle4")}}</h6>
        <p>{{$t("projectJiangXiTitleDetail4")}}</p>
        <ol>
          <li><s></s> <span>{{$t("projectJiangXiRightDetail1")}}</span></li>
          <li><s></s> <span>{{$t("projectJiangXiRightDetail2")}}</span></li>
          <li><s></s> <span>{{$t("projectJiangXiRightDetail3")}}</span></li>
          <li><s></s> <span>{{$t("projectJiangXiRightDetail4")}}</span></li>
          <li><s></s> <span>{{$t("projectJiangXiRightDetail5")}}</span></li>
          <li><s></s> <span>{{$t("projectJiangXiRightDetail6")}}</span></li>
        </ol>
        <ul>
            <li><img src="@/assets/images/project-mobile/detail/sdg3.png" alt="pic2"></li>
            <li><img src="@/assets/images/project-mobile/detail/sdg8.png" alt="pic2"></li>
            <li><img src="@/assets/images/project-mobile/detail/sdg13.png" alt="pic3"></li>
            <li><img src="@/assets/images/project-mobile/detail/sdg15.png" alt="pic4"></li>
          </ul>
     </div>
   </div>
   <public-footer></public-footer>
  </div>
</template>
<script>
export default {
  name: 'index',
  components:{
  
  },
  data() {
    return {
    }
  },
  created(){
    
  },
  mounted() {
    let dom = document.getElementById("app");
    dom.style.background='#F3F7F8'
  },
  methods:{
  }
}
</script>
<style scoped>
 .banner {
   width: 100%;
   height: 4.2rem;
   position: relative;
 }
 .banner img, .project-detail-inner img, .project-detail-inner ul li img {
   width: 100%;
   height: 100%;
   object-fit: contain;
 }
 .banner .title {
  position: absolute;
  top: 1.86rem;
  width: 100%;
  font-size: 0.36rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.5rem;
  letter-spacing: 0.06rem;
  text-align: center;
 }
.project-detail {
  padding: 0.3rem;
}
.project-detail-inner {
  padding: 0.3rem 0.2rem;
  background: #FFFFFF;
  border-radius: 8px;
}
.project-detail-inner h5 {
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.4rem;
  margin-bottom: 0.2rem;
}
.project-detail-inner .map {
  margin-bottom: 0.3rem;
  position: relative;
  padding: 0 0.34rem;
}
.project-detail-inner .map .position {
  width: 0.2rem;
  height: 0.26rem;
  position: absolute;
  bottom: 1rem;
  right: 2.04rem;
}
.project-detail-inner .map .title {
    width: 2.1rem;
    position: absolute;
    bottom: -0.2rem;
    right: -0.35rem;
}
.project-detail-inner .map .title span {
  font-size: 8px;
  font-weight: 600;
  color: #333333;
  line-height: 11px;
}
.project-detail-inner h6 {
  height: 0.56rem;
  font-size: 0.2rem;
  font-weight: 600;
  color: #333333;
  margin-top: 0.3rem;
}
.project-detail-inner p {
  font-size: 0.2rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.28rem;
}
.project-detail-inner ol {
  margin-top: 0.32rem;
}
.project-detail-inner ol li {
  margin-top: 0.14rem;
}
.project-detail-inner ol li s {
  display: inline-block;
  width: 0.28rem;
  height: 0.28rem;
  margin-right: 0.1rem;
  background-image: url('../../../assets/images/project-mobile/detail/tree.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

}
.project-detail-inner ol li span {
  font-size: 0.2rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.28rem;
}
.project-detail-inner ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.12rem;
  margin-left: 0.46rem;
}
.project-detail-inner ul li {
  width: 0.88rem;
  height: 0.88rem;
  margin-right: 0.2rem;
}
.word-spacing .project-detail-inner .map .title {
  bottom: 0;
}
</style>
