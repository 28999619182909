<template>
  <div class="homepage">
    <public-header></public-header>
    <div class="banner">
       <img src="@/assets/images/provide-mobile/banner.png" :alt='$t("bannerTitle")'>
       <p class="title">{{$t("serviceTitle")}}</p>
    </div>
    <!-- 内容区域 开始 -->
    <ul class="provide-servie-mobile">
      <li>
        <img src="@/assets/images/provide-mobile/provide1.png" :alt='$t("provideProjectItemTitle1")'>
        <p class="title">{{$t("provideProjectItemTitle1")}}</p>
        <span>{{$t("provideProjectItemContent1")}}</span>
        <router-link class="btn" :to="{path:'/carbon'}">
          <p >{{$t("moreTitle")}} <i class="el-icon-arrow-right"></i></p>
        </router-link>
      </li>
      <li>
        <img src="@/assets/images/provide-mobile/provide2.png" :alt='$t("provideProjectItemTitle2")'>
        <p class="title">{{$t("provideProjectItemTitle2")}}</p>
        <span>{{$t("provideProjectItemContent2")}}</span>
        <router-link class="btn" :to="{path:'/asset-trad'}">
          <p >{{$t("moreTitle")}} <i class="el-icon-arrow-right"></i></p>
        </router-link>
      </li>
      <li>
        <img src="@/assets/images/provide-mobile/provide3.png" :alt='$t("provideProjectItemTitle3")'>
        <p class="title">{{$t("provideProjectItemTitle3")}}</p>
        <span>{{$t("provideProjectItemContent3")}}</span>
        <router-link class="btn" :to="{path:'/corporate-climate-commitment'}">
          <p >{{$t("moreTitle")}} <i class="el-icon-arrow-right"></i></p>
        </router-link>
      </li>
    </ul>



    <!-- 内容区域 结束 -->
    <public-footer></public-footer>
  </div>
</template>
<script>
export default {
  name: 'index',

}
</script>
<style scoped>
 .banner {
   width: 100%;
   height: 4.2rem;
   position: relative;
 }
 .banner img {
   width: 100%;
   height: 100%;
   object-fit: contain;
 }
 .banner .title {
  position: absolute;
  top: 1.86rem;
  width: 100%;
  font-size: 0.36rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.5rem;
  letter-spacing: 0.06rem;
  text-align: center;
 }
.banner .btn {
  width: 1.6rem;
  height: 0.52rem;
  position: absolute;
  bottom: 0.76rem;
  left: 50%;
  margin-left: -0.8rem;
}
.provide-servie-mobile {
  padding: 1rem 0.4rem;
}
.provide-servie-mobile li {
  border-radius: 0.6rem;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.50);
  margin-bottom: 1rem;
}
.provide-servie-mobile li:last-child {
  margin: 0
}
.provide-servie-mobile li img {
  width: 100%;
  height: 4.12rem;
}
.provide-servie-mobile li .title, 
.provide-servie-mobile li span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.56rem;
  font-style: normal;
  padding: 0 0.4rem;
  display: block;
}

.provide-servie-mobile li .title {
  font-size: 0.32rem;
  margin: 0.4rem 0 0.22rem;
  color: #333333;
}
.btn {
  display: flex;
  justify-content: left;
  display: block;
}
.btn p {
  line-height: 0.28rem;
  font-size: 0.28rem !important;
  color: #9DC86A;
  font-style: normal;
  padding: 0;
  margin: 0;
  padding: 0.4rem 0.4rem;
}
</style>
