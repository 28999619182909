<template>
  <div class="homepage grassland-mobile">
    <public-header></public-header>
    <div class="banner">
       <img src="@/assets/images/grassland-mobile/banner.png" :alt='$t("bannerTitle")'>
       <p class="title">{{$t("grasslandTitle")}}</p>
    </div>
    <!-- 内容区域   开始 -->
    <div class="card">
      <p class="card-title">{{$t("sustainableTitle")}}</p>
      <p>{{$t("sustainableContent1")}}</p>
      <p>{{$t("sustainableContent2")}}</p>
      <p>{{$t("sustainableContent3")}}</p>
      <ul>
        <li>{{$t("sustainableContentli1")}}</li>
        <li>{{$t("sustainableContentli2")}}</li>
        <li>{{$t("sustainableContentli3")}}</li>
        <li>{{$t("sustainableContentli4")}}</li>
      </ul>
      <img src="@/assets/images/grassland-mobile/vcs4218.png" alt="">
      <span>{{$t("sustainablePicOrigin")}}</span>
    </div>
    <!-- 2 -->
    <div class="card">
      <p class="card-title">{{$t("grasslandCarbonTitle")}}</p>
      <p>{{$t("grasslandCarbonContent1")}}</p>
      <p>{{$t("grasslandCarbonContent2")}}</p>
      <p>{{$t("grasslandCarbonContent3")}}</p>
      <ul>
        <li>{{$t("carbonContentli1")}}</li>
        <li>{{$t("carbonContentli2")}}</li>
        <li>{{$t("carbonContentli3")}}</li>
        <li>{{$t("carbonContentli4")}}</li>
        <li>{{$t("carbonContentli5")}}</li>
      </ul>
      <p>{{$t("carbonContent4")}}</p>
      <div class="sub-card">
        <img class="clear-margin" src="@/assets/images/grassland-mobile/soc.png" alt="">
        <h6>{{$t("carbonLegend1Title")}}</h6>
        <span class="sub-title">{{$t("carbonLegend1Content1")}}</span>
        <span class="sub-title">{{$t("carbonLegend1Content2")}}</span>
      </div>
      <div class="sub-card">
        <img class="clear-margin" src="@/assets/images/grassland-mobile/carbon-co2.png" alt="">
        <h6>{{$t("carbonLegend2Title")}}</h6>
        <span class="sub-title">{{$t("carbonLegend2Content1")}}</span>
        <span class="sub-title">{{$t("carbonLegend2Content2")}}</span>
        <span class="sub-title">{{$t("carbonLegend2Content3")}}</span>
      </div>
    </div>
    <!-- 3 -->
    <div class="card card-margin30">
      <p class="card-title">{{$t("verificationTitle")}}</p>
      <p>{{$t("verificationContent")}}</p>
      <ul>
        <li>{{$t("verificationItem1")}}</li>
        <li>{{$t("verificationItem2")}}</li>
        <li>{{$t("verificationItem3")}}</li>
        <li>{{$t("verificationItem4")}}</li>
        <li>{{$t("verificationItem5")}}</li>
        <li>{{$t("verificationItem6")}}</li>
      </ul>
    </div>
    <!-- 4 -->
    <div class="card-carousel">
      <p class="card-title">{{$t("verificationTitle")}}</p>
      <carousel :height="locale === 'zh-CN' ? '7.9rem' : '8.5rem'" swiperName="swiper5"  paginationClassName="pagination5">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="@/assets/images/grassland-mobile/pic1.png" :alt='$t("carouselTitle1")'>
            <p>{{$t("carouselTitle1")}}</p>
            <span>{{$t("carouselContent1")}}</span>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/grassland-mobile/pic2.png" :alt='$t("carouselTitle2")'>
            <p>{{$t("carouselTitle2")}}</p>
            <span>{{$t("carouselContent2")}}</span>
            
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/grassland-mobile/pic3.png" :alt='$t("carouselTitle3")'>
            <p>{{$t("carouselTitle3")}}</p>
            <span>{{$t("carouselContent3")}}</span>
          </div>
        </div>
      </carousel>
      <div class="marker">
        <span>Reference:</span>
        <span>[1] Dondini, M., Martin, M., De Camillis, C., Uwizeye, A., Soussana, J. F., Robinson, T. & Steinfeld, H. 2023. Global assessment of soil carbon in grasslands – From current stock estimates to sequestration potential. FAO Animal Production and Health Paper No. 187. Rome, FAO. https://doi.org/10.4060/cc3981en</span>
        <span>[2] Yue Li, Hongming, D., Timm, T., Shiping, W., Benjamin, H., Leslie, L., Neil, B. 2021. Approved VCS Methodology  VM0026: Sustainable Grassland Management. FAO. https://verra.org/methodologies/vm0026-methodology-for-sustainable-grassland-management-sgm-v1-0/</span>
        <span>[3] Krista, E., Jessalyn, B., Hector, M., Krista, E., Anna, D. 2022. The Lasting Effects of Overgrazing on Rangeland Ecosystems. South Dakota State University Extension. https://extension.sdstate.edu/lasting-effects-overgrazing-rangeland-ecosystems</span>
      </div>
    </div>
    <!-- 5 -->
    <div class="card-carousel">
      <p class="card-title">{{$t("acceptanceTitle")}}</p>
      <div class="cimg-card">
        <img class="cimg" src="@/assets/images/grassland-mobile/project.png" alt="">
        <p class="card-sub-title">{{$t("acceptanceSubTitle")}}</p>
        <router-link class="btn" :to="{path:'/project'}">
          <p >{{$t("moreTitle")}} <i class="el-icon-arrow-right"></i></p>
        </router-link>
      </div>
    </div>
    <!-- 内容区域   结束 -->
    <public-footer></public-footer>
  </div>
</template>
<script>
import carousel from '@/components/carousel.vue';
export default {
  name: 'index',
  components: {
    carousel
  },
  data() {
    return {
      locale: ''
    }
  },
  created() {
    this.locale = localStorage.getItem("locale");
    this.locale = this.locale ? this.locale : "zh-CN";
  },
}
</script>
<style scoped>
 .banner {
   width: 100%;
   height: 4.2rem;
   position: relative;
 }
 .banner img ,.about-road img,.team-list li img{
   width: 100%;
   height: 100%;
   object-fit: contain;
 }
 .banner .title {
  position: absolute;
  top: 1.86rem;
  width: 100%;
  font-size: 0.36rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.5rem;
  letter-spacing: 0.06rem;
  text-align: center;
 }
.card {
  margin: 1rem 0.4rem 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 0px rgba(161,208,107,0.5);
  border-radius: 0.4rem;
  padding: 0.4rem;
 }
 .card p.card-title, .card-carousel p.card-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.4rem;
  color: #333333;
  line-height: 0.66rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
}
.card p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.44rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin-bottom: 0.4rem
}
.card ul li{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.6rem;
  text-align: left;
  font-style: normal;
  margin-bottom: 0.2rem;
  padding-left: 0.68rem;
  position: relative;
}
.card ul li::after {
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 0.1rem;
  background: #333333;
  content: '';
  display: inline-block;
  position: absolute;
  left: 0.2rem;
  top: 0.2rem;
}
.card img {
  margin-top: 0.4rem;
  width: 100%;
}
.card span {
  display: block;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.24rem;
  color: #666666;
  line-height: 0.36rem;
  text-align: center;
  font-style: normal;
  margin-top: 0.2rem;
}

.card .clear-margin {
  margin: 0;
}
.card .sub-card {
  box-shadow: 0px 0px 4px 0px rgba(161,208,107,0.5);
  border-radius: 0.4rem;
  padding-bottom: 0.4rem;
  margin-bottom: 0.6rem
}
.card .sub-card h6 {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.44rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin: 0.4rem 0;
  padding: 0 0.4rem
}
.card .sub-card .sub-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.28rem;
  text-align: left;
  font-style: normal;
  margin-top: 0.4rem;
  padding: 0 0.4rem
}
.card .sub-card .sub-title:last-child {
  margin-bottom: 0;
}
.card-margin30 {
  margin-top: 0.6rem;
}
.card-carousel p.card-title {
  padding: 0 0.8rem;
  margin: 0.4rem 0;
  font-size: 0.40rem;
}
.card-carousel .swiper-slide img {
  width: 100%;
  height: 3.76rem;
}
.card-carousel .swiper-slide p,
.card-carousel .swiper-slide span {
  padding: 0 0.4rem
}
.card-carousel .swiper-slide p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.32rem;
  color: #333333;
  line-height: 0.32rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin: 0.4rem 0 0.32rem;
}
.card-carousel .swiper-slide span {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.56rem;
  text-align: left;
  font-style: normal;
  display: block;
}
.card-carousel .marker {
  padding: 0 0.8rem;
  width: 100%;
}
.card-carousel .marker span{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.24rem;
  color: #666666;
  line-height: 0.48rem;
  text-align: justify;
  font-style: normal;
}
.card-carousel .cimg-card {
  box-shadow: 0px 0px 4px 0px rgba(161,208,107,0.5);
  border-radius: 0.4rem;

  margin: 0 0.8rem 1rem;
}
.card-carousel .cimg {
  width: 100%;
  height: 3.76rem;
}
.card-carousel .card-sub-title {
  padding: 0 0.2rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.3rem;
  color: #333333;
  line-height: 0.44rem;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin: 0.4rem 0 0;
}
.card-carousel .btn {
  padding: 0 0.2rem;
  color: #9DC86A;
}
.card-carousel .btn p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 0.28rem;
  color: #9DC86A;
  line-height: 0.28rem;
  text-align: left;
  font-style: normal;
  padding:  0.2rem;
}
</style>