<template>
  <div class="homepage mobile-project-page">
    <public-header></public-header>
    <!-- banner -->
    <div class="banner">
       <img src="@/assets/images/project-mobile/banner.png" :alt='$t("bannerTitle")'>
       <p class="title">{{$t("projectTitle")}}</p>
    </div>
    <!-- 项目名称 --> 
    <ul>
      <!-- 草原可持续管理 -->
      <li class="project-mobile-item">
        <p class="mobile-index-title">{{$t("projectClassItemTitle1")}}</p>
        <carousel :height="locale === 'zh-CN' ? '8.12rem' : '8.4rem'" swiperName="swiper5"  paginationClassName="pagination5">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="@/assets/images/project-mobile/project-gz.png" :alt='$t("projectClassItemProjectTitle1")'>
            <p>{{$t("projectClassItemProjectTitle1")}}</p>
            <span>{{$t("projectClassItemProjectTime1")}}</span>
            <router-link class="btn" :to="{path:'/guazhou-project'}">
              <p >{{$t("moreTitle")}}<i class="el-icon-arrow-right"></i></p>
            </router-link>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/project-mobile/project-gs.png" :alt='$t("projectClassItemProjectTitle1")'>
            <p>{{$t("projectClassItemProjectTitle2")}}</p>
            <span>{{$t("projectClassItemProjectTime2")}}</span>
            <router-link class="btn" :to="{path:'/project'}">
              <p >{{$t("moreTitle")}} <i class="el-icon-arrow-right"></i></p>
            </router-link>
          </div>
        </div>
      </carousel>
      </li>
      <!-- 造林与再造林 -->
      <li class="project-mobile-item">
        <p class="mobile-index-title">{{$t("projectClassItemTitle2")}}</p>
        <carousel :height="locale === 'zh-CN' ? '8.12rem' : '8.4rem'" swiperName="swiper5"  paginationClassName="pagination5">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="@/assets/images/project-mobile/project-jx.png" :alt='$t("projectClassItemProjectTitle1")'>
            <p>{{$t("projectItemTitle1")}}</p>
            <span>{{$t("projectItemyear1")}}</span>
            <router-link class="btn" :to="{path:'/forestFarmCarbonSinkProject-Jiangxi'}">
              <p >{{$t("moreTitle")}}<i class="el-icon-arrow-right"></i></p>
            </router-link>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/project-mobile/project-fc.png" :alt='$t("projectClassItemProjectTitle1")'>
            <p>{{$t("projectItemTitle6")}}</p>
            <span>{{$t("projectItemyear6")}}</span>
            <router-link class="btn" :to="{path:'/afforestation-Fangcheng'}">
              <p >{{$t("moreTitle")}} <i class="el-icon-arrow-right"></i></p>
            </router-link>
          </div>
        </div>
      </carousel>
      </li>
      <!-- 森林经营管理 -->
      <li class="project-mobile-item">
        <p class="mobile-index-title">{{$t("projectClassItemTitle3")}}</p>
        <carousel :height="locale === 'zh-CN' ? '8.12rem' : '8.4rem'" swiperName="swiper5"  paginationClassName="pagination5">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="@/assets/images/project-mobile/project-weq.png" :alt='$t("projectClassItemProjectTitle1")'>
            <p>{{$t("projectItemTitle7")}}</p>
            <span>{{$t("projectItemyear7")}}</span>
            <router-link class="btn" :to="{path:'/conversionOfLoggedToProtectedForest-InnerMongolia'}">
              <p >{{$t("moreTitle")}}<i class="el-icon-arrow-right"></i></p>
            </router-link>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/project-mobile/project-kyh.png" :alt='$t("projectClassItemProjectTitle1")'>
            <p>{{$t("projectItemTitle8")}}</p>
            <span>{{$t("projectItemyear8")}}</span>
            <router-link class="btn" :to="{path:'/conversionOfLoggedToProtectedForest-Keyihe'}">
              <p >{{$t("moreTitle")}} <i class="el-icon-arrow-right"></i></p>
            </router-link>
          </div>
        </div>
      </carousel>
      </li>
    </ul>
    <public-footer></public-footer>
  </div>
</template>
<script>
import carousel from '@/components/carousel.vue';
export default {
  name: 'index',
  components:{
    carousel,
  },
  data() {
    return {
      locale: ''
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
}
</script>
<style scoped>
 .banner {
   width: 100%;
   height: 4.2rem;
   position: relative;
 }
 .banner img {
   width: 100%;
   height: 100%;
   object-fit: contain;
 }
 .banner .title {
  position: absolute;
  top: 1.86rem;
  width: 100%;
  font-size: 0.36rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.5rem;
  letter-spacing: 0.06rem;
  text-align: center;
 }
.banner .btn {
  width: 1.6rem;
  height: 0.52rem;
  position: absolute;
  bottom: 0.76rem;
  left: 50%;
  margin-left: -0.8rem;
}
.mobile-index-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.66rem;
  font-style: normal;
  text-align: center;
  font-size: 0.5rem;
  width: 80%;
  margin: 0 auto 0.4rem;
}
.mobile-project-page ul{
  padding-bottom: 1rem
}
.mobile-project-page li {
  padding: 1rem 0 0;
}
::v-deep  .project-mobile-item .swiper-wrapper img {
  width: 100%;
  height: 4.6rem;
}
::v-deep .project-mobile-item .swiper-wrapper p,
::v-deep .project-mobile-item .swiper-wrapper span {
  display: block;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: left;
  font-style: normal;
  padding: 0 0.4rem;
}
::v-deep .project-mobile-item .swiper-wrapper p {
  font-size: 0.3rem;
  margin: 0.5rem 0 0.3rem;
}
::v-deep .project-mobile-item .swiper-wrapper span {
  font-size: 0.28rem;
  margin-bottom: 0.3rem;
}
::v-deep .project-mobile-item .swiper-wrapper .swiper-slide {
  position: relative;
}
::v-deep .project-mobile-item .swiper-wrapper .btn {
  display: flex;
  justify-content: left;
  padding: 0 0.4rem;
  position: absolute;
  left: 0;
  bottom: 0.4rem;
}
::v-deep .project-mobile-item .swiper-wrapper .btn a {
  position: relative;
}

::v-deep .project-mobile-item .swiper-wrapper .btn P {
  line-height: 0.28rem;
  font-size: 0.28rem !important;
  color: #9DC86A;
  text-align: center !important;
  font-style: normal;
  padding: 0;
  margin: 0
}
</style>
