<template>
  <div class="detail" :class="locale === 'zh-CN' ? '' : 'detail-add-height'">
    <detail-header :logoFlag="false"></detail-header>
    <div class="detail-content center">
      <div class="detail-img">
        <img src="../../../assets/images/detail/beiBei.png" alt="carl">
      </div>
      <div class="detail-article">
        <h3>{{$t("teamItem4")}}</h3>
        <h4>{{$t("teamItemPost4")}}</h4>
        <h4>{{$t("teamItemGet4")}}</h4>
        <h4>{{$t("teamItemGet41")}}</h4>
        <a href="https://www.linkedin.com/in/%E5%AE%8B%E8%B4%9D%E8%B4%9D-beibei-song-789061154/"><img src="../../..//assets/images/detail/link.png" alt=""></a>
        <p>{{$t("teamItemDetail4")}}</p>
        <div class="button">
          <router-link :to="{path:'aboutUs'}">
            <span>{{$t("back")}}</span>
          </router-link>
        </div>
      </div>
    </div>
    <public-footer></public-footer>
  </div>
</template>
<script>
export default {
  name: "beiBei",
  data() {
    return {
      locale: ''
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
}
</script>
<style>
@import "../../../assets/css/detail.css";
.detail-add-height {
  height: 820px;
}
</style>