<template>
  <div class="mobile-carousel-paginattion title-btn" :style="'height:' + height">
    <div class="swiper-container" :class="swiperName">
      <slot></slot>
      
      <div class="swiper-pagination" :class="paginationClassName"></div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"
export default {
  props: {
    height: {
      type: String,
      default: '5rem'
    },
    paginationClassName: {
      type: String,
      default: 'pagination1'
    },
    swiperName: {
      type: String,
      default: 'swiper1'
    }
  },
  mounted() {
    let _this = this
    let swiperName = '.' + _this.swiperName
    let paginationClassName = '.' + _this.paginationClassName
    this.$nextTick(() => {
      new Swiper (swiperName, { // eslint-disable-line no-unused-vars
        // slidesPerView : 'auto',
        // loop: true,
        // direction: 'horizontal',
        width: window.innerWidth,
        pagination: {
          el: paginationClassName,
        },
    })
    })
  }
}

</script>
<style>
/* 整体高度 */
.mobile-carousel-paginattion .swiper-container {
  height: 100%;
}
.mobile-carousel-paginattion .swiper-container-horizontal>.swiper-pagination-bullets,
.mobile-carousel-paginattion .swiper-pagination-custom,
.mobile-carousel-paginattion .swiper-pagination-fraction {
  width: 4rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
}
.mobile-carousel-paginattion .swiper-pagination-bullet {
  display: flex;
  flex: 1;
  border-radius: 0;
  margin: 0 !important;
  background: transparent;
}
.mobile-carousel-paginattion .swiper-pagination-bullet-active {
  background: #31E07F!important;
  border-radius: 4px;
}
</style>