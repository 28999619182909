<template>
  <div class="homepage" :class="{'index-mobile': locale !== 'zh-CN'}">
    
    <public-header></public-header>
    <!-- banner -->
    <div class="banner">
       <img src="@/assets/images/index-mobile/banner.png" :alt='$t("bannerTitle")'>
       <p class="title">{{$t("bannerTitle")}}</p>
       <div class="btn">
         <router-link :to="{path:'/project'}">
          <img src="@/assets/images/index-mobile/banner-btn.png" alt='btn'>
          <p>{{$t("moreTitle")}}</p>
        </router-link>
       </div>
    </div>
    <!--  文案 使命 -->
     <div class="mobile-mission title-btn">
      <img src="@/assets/images/index-mobile/mission.png" :alt='$t("projectTitle")' />
      <div>
        <p class="mobile-index-title title-after">{{$t("missionTitle1")}}</p>
        <p class="mobile-mission-content">{{$t("missionAction1")}}</p>
        <p class="mobile-index-title title-after">{{$t("missionTitle2")}}</p>
        <p class="mobile-mission-content clear-margin-bottom">{{$t("missionAction2")}}</p>
      </div>
     </div>
     <!-- 文案2 服务  -->
     <div class="mobile-service title-btn">
      <p class="mobile-index-title">{{$t("servieceNewTitle")}}</p>
      <ul>
        <li>
          <router-link :to="{path:'/carbon'}">
             <img src="@/assets/images/index-mobile/project-1.png" :alt='$t("servieceNewPic1")'>
             <p>{{$t("servieceNewPic1")}}</p>
           </router-link>
        </li>
        <li>
          <router-link :to="{path:'/asset-trad'}">
             <img src="@/assets/images/index-mobile/project-2.png" :alt='$t("servieceNewPic2")'>
             <p>{{$t("servieceNewPic2")}}</p>
           </router-link>
        </li>
        <li>
          <router-link :to="{path:'/corporate-climate-commitment'}">
             <img src="@/assets/images/index-mobile/project-3.png" :alt='$t("servieceNewPic3")'>
             <p>{{$t("servieceNewPic3")}}</p>
           </router-link>
        </li>
      </ul>
      <div class="btn">
        <router-link :to="{path:'/provideService'}">
          <img src="@/assets/images/index-mobile/title-btn.png" alt='btn'>
          <p>{{$t("moreTitle")}}</p>
        </router-link>
       </div>
     </div>
    <!-- 文案2 项目 -->
    <div class="mobile-project">
      <p class="mobile-index-title">{{$t("projectTitle1")}}</p>
      <!-- 实现下面的内容 -->
      <carousel :height="locale === 'zh-CN' ? '11rem' : '12rem'">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="@/assets/images/index-mobile/project-gz.png" :alt='$t("projectClassItemProjectTitle1")'>
            <p>{{$t("projectClassItemProjectTitle1")}}</p>
            <span>{{$t("projectClassItemProjectTime1")}}</span>
            <div class="btn">
              <router-link :to="{path:'/guazhou-project'}">
                <img src="@/assets/images/index-mobile/title-btn.png" alt='btn'>
                <p>{{$t("moreTitle")}}</p>
              </router-link>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/index-mobile/project-weq.png" :alt='$t("projectItemTitle7")'>
            <p>{{$t("projectItemTitle7")}}</p>
            <span>{{$t("projectItemyear7")}}</span>
            <div class="btn">
              <router-link :to="{path:'/conversionOfLoggedToProtectedForest-InnerMongolia'}">
                <img src="@/assets/images/index-mobile/title-btn.png" alt='btn'>
                <p>{{$t("moreTitle")}}</p>
              </router-link>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/index-mobile/project-kyh.png" :alt='$t("projectItemTitle8")'>
            <p>{{$t("projectItemTitle8")}}</p>
            <span>{{$t("projectItemyear8")}}</span>
            <div class="btn">
              <router-link :to="{path:'/conversionOfLoggedToProtectedForest-Keyihe'}">
                <img src="@/assets/images/index-mobile/title-btn.png" alt='btn'>
                <p>{{$t("moreTitle")}}</p>
              </router-link>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/index-mobile/project-fc.png" :alt='$t("projectItemTitle6")'>
            <p>{{$t("projectItemTitle6")}}</p>
            <span>{{$t("projectItemyear6")}}</span>
            <div class="btn">
              <router-link :to="{path:'/afforestation-Fangcheng'}">
                <img src="@/assets/images/index-mobile/title-btn.png" alt='btn'>
                <p>{{$t("moreTitle")}}</p>
              </router-link>
            </div>
          </div>
          <div class="swiper-slide">
            <img src="@/assets/images/index-mobile/project-jx.png" :alt='$t("projectItemTitle1")'>
            <p>{{$t("projectItemTitle1")}}</p>
            <span>{{$t("projectItemyear1")}}</span>
            <div class="btn">
              <router-link :to="{path:'/forestFarmCarbonSinkProject-Jiangxi'}">
                <img src="@/assets/images/index-mobile/title-btn.png" alt='btn'>
                <p>{{$t("moreTitle")}}</p>
              </router-link>
            </div>
          </div>
        </div>
      </carousel>
    </div>
    <!-- 文案3 最新消息 -->
    <div class="mobile-news title-btn">
      <p class="mobile-index-title">{{$t("newsTitle")}}</p>
      <img src="@/assets/images/index-mobile/news.png" :alt='$t("newsTitle")'>
      <div class="btn">
        <a :href="`${$t('ahref')}`">
          <img src="@/assets/images/index-mobile/title-btn.png" alt='btn'>
          <p>{{$t("moreTitle")}}</p>
        </a>
       </div>
    </div>
    <!-- 文案4 合作单位 -->
    <div class="mobile-vip title-btn">
      <p class="mobile-index-title">{{$t("memberOrganizations")}}</p>
      <carouselPaination height="6.5rem" swiperName="swiper1"  paginationClassName="pagination2">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <a href="https://www.ieta.org/"><img src="@/assets/images/index-mobile/vip1.png" style="width: 2rem; height: 0.76rem;" alt=""></a>
            <a href="http://www.cecaweb.org.cn/"><img src="@/assets/images/index-mobile/vip2.png" style="width: 2rem; height: 0.76rem;" alt=""></a>
            <a href="http://www.acet-ceca.com/"><img src="@/assets/images/index-mobile/vip3.png" style="width: 3.7rem; height: 1.44rem;" alt=""></a>
          </div>
          <div class="swiper-slide">
            <a href="https://climatechaincoalition.org/"><img src="@/assets/images/index-mobile/vip4.png" style="width: 4.8rem; height: 0.96rem" alt=""></a>
            <a href="https://www.geidco.org.cn/"><img src="@/assets/images/index-mobile/vip5.png" style="width: 4.88rem; height: 0.64rem" alt=""></a>
            <a href="https://www.cbeex.com.cn/"><img src="@/assets/images/index-mobile/vip6.png" style="width: 3.16rem; height: 0.76rem" alt=""></a>
          </div>
        </div>
      </carouselPaination>
    </div>
    <!-- 文案5 合作单位 -->
    <div class="mobile-partner">
      <p class="mobile-index-title">{{$t("clientsPartners")}}</p>
      <carouselPaination height="6.5rem" swiperName="swiper3" paginationClassName="pagination3">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <a href="https://www.unep.org/"><img src="@/assets/images/index-mobile/partner1.png" style="width: 0.8rem; height: 0.56rem" alt=""></a>
            <a href="https://www.ruc.edu.cn/"><img src="@/assets/images/index-mobile/partner2.png" style="width: 1.16rem; height: 0.76rem" alt=""></a>
            <a href="https://www.zafu.edu.cn/"><img src="@/assets/images/index-mobile/partner3.png" style="width: 1.04rem; height: 0.76rem" alt=""></a>
          </div>
          <div class="swiper-slide">
            <a href="https://www.cecep.cn/"><img src="@/assets/images/index-mobile/partner4.png" style="width: 0.76rem; height: 0.76rem" alt=""></a>
            <a href="http://www.caf.ac.cn/"><img src="@/assets/images/index-mobile/partner5.png" style="width: 4rem; height: 0.76rem" alt=""></a>
            <a href="https://www.cgs.gov.cn/gzdt/zsdw/202310/t20231031_746432.html"><img src="@/assets/images/index-mobile/partner6.png" style="width: 1.06rem; height: 0.76rem" alt=""></a>
          </div>
        </div>
      </carouselPaination>
    </div>
    <public-footer></public-footer>
  </div>
</template>
<script>
import carousel from '@/components/carousel.vue';
import carouselPaination from '@/components/carouselPaination.vue';
export default {
  name: 'index',
  components:{
    carousel,
    carouselPaination
  },
  data() {
    return {
      locale: ''
    }
  },
  created() {
    this.locale = localStorage.getItem('locale')
    this.locale = this.locale ? this.locale : 'zh-CN'
  },
}
</script>
<style scoped>
 .banner {
   width: 100%;
   height: 7.6rem;
   position: relative;
 }
 .banner img {
   width: 100%;
   height: 100%;
   /* object-fit: contain; */
 }
 .banner .title {
  position: absolute;
  top: 2rem;
  width: 100%;
  font-size: 0.56rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.8rem;
  letter-spacing: 0.06rem;
  text-align: center;
 }
.banner .btn {
  width: 2.3rem;
  height: 0.8rem;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}
.btn img{
  width: 100%;
  height: 100%;
  object-fit: contain;
 
}
.btn p {
   position: absolute;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.28rem;
    line-height: 0.8rem;
    text-align: center;
    width: 100%;
    color: #FFFFFF;
}

.title-btn .btn {
  display: flex;
  justify-content: center;
}
.title-btn .btn a {
  position: relative;
}
.title-btn .btn img{
  width: 2.4rem;
  height: 1rem;
}
.title-btn .btn P {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1rem;
  text-align: center;
  font-size: 0.28rem;
  color: #FFFFFF;
  /* letter-spacing: 0.08rem; */
  text-align: center;
  font-style: normal;
}
.index-mobile .btn p{
  font-size: 0.28rem;
  letter-spacing: 0;
}
/* 文案2 开始   */
.mobile-mission {
  padding: 0.9rem 0.36rem 0.4rem;
}
.mobile-mission img {
  width: 100%;
  height: 6rem;
  
}
.mobile-mission div {
  padding: 0.4rem 0.32rem;
}
.mobile-index-title {
  position: relative;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 0.48rem;
  color: #333333;
  height: 0.9rem;
  line-height: 0.66rem;
  text-align: left;
  font-style: normal;
}
.title-after {
  display: inline-block;
}
.title-after::after {
  display: inline-block;
  content: '';
  width: 100%;
  height: 2px;
  background: linear-gradient(to bottom, #626262, #333);
  position: absolute;
  top: 0.9rem;
  left: 0;
}
.mobile-mission-content {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.28rem;
  color: #333333;
  line-height: 0.4rem;
  text-align: left;
  font-style: normal;
  margin: 0.2rem 0 0.6rem;
}
.mobile-mission-content:last-child {
  margin-bottom: 0;
}
.mobile-service,
.mobile-news,
.mobile-partner
{
  width: 100%;
  background: #F3F7F8;
  padding: 1rem 0.4rem;
}
.mobile-service .mobile-index-title,
.mobile-project .mobile-index-title,
.mobile-news .mobile-index-title,
.mobile-vip .mobile-index-title,
.mobile-partner .mobile-index-title {
  text-align: center;
  margin-bottom: 0.36rem;
}

.mobile-service li {
  height: 6.6rem;
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.50);
  border-radius: 0.6rem;
  cursor: pointer;
  margin-bottom: 0.84rem;
}
.mobile-service li img {
  height: 5.5rem;
  width: 100%;
}
.mobile-service li a {
  display: block;
  height: 6.6rem;
}
.mobile-service li p {
  height: 1.1rem;
  line-height: 1.1rem;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 0.32rem;
  color: #333333;
  text-align: center;
  font-style: normal;
}
/* 文案3 项目 */
.mobile-project {
  width: 100%;
  padding: 1rem 0;
}
::v-deep .mobile-project .swiper-wrapper img {
  width: 100%;
  height: 6.9rem;
}
::v-deep .mobile-project .swiper-wrapper p,
::v-deep .mobile-project .swiper-wrapper span {
  display: block;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: left;
  font-style: normal;
  padding: 0 0.4rem;
}
::v-deep .mobile-project .swiper-wrapper p {
  font-size: 0.3rem;
  margin: 0.5rem 0 0.3rem;
}
::v-deep .mobile-project .swiper-wrapper span {
  font-size: 0.28rem;
  margin-bottom: 0.3rem;
}

::v-deep .mobile-project .title-btn .swiper-wrapper .btn {
  display: flex;
  justify-content: left;
  padding: 0 0.4rem;
}
::v-deep .mobile-project .title-btn .swiper-wrapper .btn a {
  position: relative;
}
::v-deep .mobile-project .title-btn .swiper-wrapper .btn img{
  width: 2.4rem;
  height: 0.8rem !important;
}
::v-deep .mobile-project .title-btn .swiper-wrapper .btn p {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 0.8rem;
  font-size: 0.28rem !important;
  color: #FFFFFF;
  letter-spacing: 0 !important;
  text-align: center !important;
  font-style: normal;
  padding: 0;
  margin: 0
}
.mobile-carousel-paginattion .swiper-wrapper .swiper-slide img {
  object-fit: contain;
}
.mobile-news img {
  width: 100%;
  height: 6.6rem;
  margin-bottom: 0.6rem;
}

/* 文案4 5  合作单位 */
.mobile-vip ,
.mobile-partner {
  width: 100%;
  padding: 1rem 0;
}
::v-deep .mobile-vip .swiper-slide,
::v-deep .mobile-partner .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .mobile-vip .swiper-slide a,
::v-deep .mobile-partner .swiper-slide a {
  display: block;
  margin-bottom: 1rem;
}








</style>
